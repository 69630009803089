import { Component, Vue, Emit } from 'vue-property-decorator';

@Component
/**
 * Contain all necessary methods and events for work dialog state.
 */
export class DialogClass extends Vue {
  public isOpen = false;

  protected open(...params: never[]): void {
    this.isOpen = true;

    this.dialogOpened(...params);
  }
  /**
   * Fired when dialog was opened
   */
  protected dialogOpened(..._params: never[]): void {
    // Fired when dialog was opened
  }

  @Emit('closed')
  protected close(): void {
    this.isOpen = false;
    this.dialogClosed();
  }
  /**
   * Fired when dialog was closed
   */
  protected dialogClosed(): void {
    // Fired when dialog was closed
  }
}
