import { Component, Prop } from 'vue-property-decorator';
import { VehicleDictionaryService } from '@/services';
import { FormControlClass } from '@/core';

@Component
export default class VehicleDictionarySelect extends FormControlClass<string> {
  @Prop({ required: true })
  private readonly dictionaryUrl!: string;

  protected async setData(): Promise<void> {
    try {
      this.isLoading = true;

      let items = await VehicleDictionaryService.findAll(this.dictionaryUrl, true);
      if (this.customFilter !== undefined) {
        items = items.filter(this.customFilter);
      }

      this.items = items.map(d => ({ value: d.id, text: d.name }));
    } finally {
      this.isLoading = false;
    }
  }
}
